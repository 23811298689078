import { type FunctionComponent } from 'react';
import type { LinkStyleProps } from '../link/link.types';
import { StyledLink } from '../link/styled-link.component';

export type ClickablePhoneNumberProps = LinkStyleProps & {
	phoneNumber: string;
};

export const ClickablePhoneNumber: FunctionComponent<ClickablePhoneNumberProps> = ({ phoneNumber, ...props }) => (
	<StyledLink url={`tel:${phoneNumber}`} {...props}>
		{phoneNumber}
	</StyledLink>
);
