import { type MutationResult } from '@apollo/client';
import { type FunctionComponent, type SyntheticEvent, useState } from 'react';
import { type AddProductToCartMutation, type ButtonStyle } from '../../../__generated__/graphql-client-types';
import { handleKeys } from '../../../helpers/keyboard/keyboard.helper';
import { ActionTypeLabelList, ADD_TO_CART_BUTTON_TYPE } from '../../../helpers/product-helper/add-to-cart-button/add-to-cart-button.helper';
import { type ButtonSize } from '../../buttons';
import { StyledButton } from '../../buttons/styled-button/styled-button.component';
import { ContactToPurchasePopover } from './contact-to-purchase-popover.component';

export type AddToCartButtonProps = {
	handleOnClick: (event: SyntheticEvent<Element>) => void;
	buttonLabel: string;
	disabled: boolean;
	isBusy?: boolean;
	busyText?: string;
	buttonSize?: NonNullable<Extract<ButtonSize, 'DEFAULT' | 'SMALL' | 'LARGE'>>;
	buttonStyle?: Extract<ButtonStyle, 'PRIMARY' | 'SECONDARY'>;
};

export const DefaultAddToCartButton: FunctionComponent<AddToCartButtonProps> = ({
	handleOnClick,
	buttonLabel,
	disabled,
	isBusy,
	buttonStyle = 'PRIMARY',
	buttonSize,
	busyText = 'Adding to Cart...'
}) => {
	return (
		<StyledButton
			disabled={disabled || isBusy}
			automationHook={'add-to-cart'}
			testId="add-to-cart"
			onClick={handleOnClick}
			size={buttonSize || 'DEFAULT'}
			isBusy={isBusy}
			busyText={busyText}
			buttonStyle={buttonStyle}>
			<span className="nowrap">{buttonLabel}</span>
		</StyledButton>
	);
};

export const StickyAddToCartButton: FunctionComponent<AddToCartButtonProps> = ({ handleOnClick, buttonLabel, disabled, isBusy }) => {
	return (
		<>
			<div
				className={`flex dn-ns justify-center items-center bg-theme-primary theme-white ph3 ${
					isBusy ? 'not-allowed o-50' : 'pointer'
				}`}
				style={{ height: 54 }}
				data-testid="mobile-sticky-add-to-cart-button"
				onClick={handleOnClick}
				role={`button`}
				onKeyPress={handleKeys(['Enter', ' '], handleOnClick)}
				tabIndex={0}>
				<span className="nowrap">{isBusy ? 'Adding to Cart...' : buttonLabel}</span>
			</div>
			<div className="dn db-ns">
				<StyledButton
					disabled={disabled || isBusy}
					automationHook={'add-to-cart'}
					onClick={handleOnClick}
					testId="sticky-add-to-cart-button"
					isBusy={isBusy}
					busyText={'Adding to Cart...'}>
					<span className="nowrap">{buttonLabel}</span>
				</StyledButton>
			</div>
		</>
	);
};

export type AddToCartButtonFactoryProps = {
	buttonType: string;
	addToCartButtonType: ADD_TO_CART_BUTTON_TYPE;
	status: MutationResult<AddProductToCartMutation>;
	isConfigurable?: boolean;
	hasSalesRestrictions?: boolean;
	isShowroomOnlyProduct?: boolean;
	isBusy?: boolean;
	busyText?: string;
} & Pick<AddToCartButtonProps, 'handleOnClick' | 'disabled'>;

export const AddToCartButtonFactory: FunctionComponent<AddToCartButtonFactoryProps> = ({
	buttonType,
	addToCartButtonType = ADD_TO_CART_BUTTON_TYPE.DEFAULT,
	handleOnClick,
	isConfigurable,
	hasSalesRestrictions,
	isShowroomOnlyProduct,
	disabled,
	status,
	isBusy,
	busyText
}) => {
	const [isContactToPurchaseOpen, setIsContactToPurchaseOpen] = useState(false);

	let AddToCartButton: JSX.Element;
	let buttonLabel: ActionTypeLabelList;

	switch (addToCartButtonType) {
		case ADD_TO_CART_BUTTON_TYPE.STICKY:
			AddToCartButton = (
				<StickyAddToCartButton
					handleOnClick={handleOnClick}
					buttonLabel={disabled && buttonType === 'STANDARD' ? ActionTypeLabelList.CONFIGURE : ActionTypeLabelList[buttonType]}
					disabled={status?.loading || false}
					isBusy={isBusy}
				/>
			);
			break;
		case ADD_TO_CART_BUTTON_TYPE.PLA:
			AddToCartButton = (
				<DefaultAddToCartButton
					handleOnClick={handleOnClick}
					buttonLabel={isConfigurable ? ActionTypeLabelList.CONFIGURE : ActionTypeLabelList[buttonType]}
					disabled={status?.loading || false}
					isBusy={isBusy}
				/>
			);
			break;
		case ADD_TO_CART_BUTTON_TYPE.BUY_AGAIN:
			AddToCartButton = (
				<DefaultAddToCartButton
					handleOnClick={handleOnClick}
					buttonLabel={ActionTypeLabelList.BUY_IT_AGAIN}
					disabled={status?.loading || false}
					isBusy={isBusy}
				/>
			);
			break;
		case ADD_TO_CART_BUTTON_TYPE.PLP:
			if (isConfigurable) {
				buttonLabel = ActionTypeLabelList.CONFIGURE;
			} else if (hasSalesRestrictions || isShowroomOnlyProduct) {
				buttonLabel = ActionTypeLabelList.VIEW_DETAILS;
			} else {
				buttonLabel = ActionTypeLabelList[buttonType];
			}

			AddToCartButton = (
				<DefaultAddToCartButton
					handleOnClick={handleOnClick}
					buttonLabel={buttonLabel}
					disabled={status?.loading || false}
					isBusy={isBusy}
					busyText={busyText}
					buttonStyle={isConfigurable || hasSalesRestrictions || isShowroomOnlyProduct ? 'SECONDARY' : 'PRIMARY'}
				/>
			);
			break;
		case ADD_TO_CART_BUTTON_TYPE.BUNDLE:
			AddToCartButton = (
				<DefaultAddToCartButton
					handleOnClick={handleOnClick}
					buttonLabel={isConfigurable ? ActionTypeLabelList.CONFIGURE : ActionTypeLabelList[buttonType]}
					disabled={disabled || false}
					buttonSize={'SMALL'}
					isBusy={isBusy}
				/>
			);
			break;
		default:
			AddToCartButton = (
				<DefaultAddToCartButton
					handleOnClick={handleOnClick}
					buttonLabel={ActionTypeLabelList[buttonType]}
					disabled={disabled || status?.loading}
					isBusy={isBusy}
				/>
			);
	}
	return buttonType === 'STANDARD' ? (
		AddToCartButton
	) : (
		<ContactToPurchasePopover
			isContactToPurchaseOpen={isContactToPurchaseOpen}
			setIsContactToPurchaseOpen={setIsContactToPurchaseOpen}
			disabled={disabled}
			loading={status?.loading}
			label={ActionTypeLabelList[buttonType]}
			showOnBottom={addToCartButtonType === ADD_TO_CART_BUTTON_TYPE.STICKY}
		/>
	);
};
